import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Header.scss';
import logo from './logo.png'

const Header = () => {
    const { t, i18n } = useTranslation();
    const lang = i18n.language.substring(0, 2); // for (ru-Ru)-like cases

    useEffect(() => {
        i18n.changeLanguage(i18n.language.substring(0, 2));
    }, []);

    return (
        <div className='navbar'>
            <div className='logo'>
                <div className="wrapper">
                    <a href="https://betullahome.com">
                        <img className="img-logo" src={logo} />
                    </a>
                </div>
                <p>Online reservation</p>
            </div>
            <div className='lang'>
                <div
                    onClick={() => i18n.changeLanguage('ru')}
                    style={{ fontWeight: lang === 'ru' ? 600 : 100 }}
                >
                    RU
                </div>
                <div
                    onClick={() => i18n.changeLanguage('en')}
                    style={{ fontWeight: lang === 'en' ? 600 : 100 }}
                >
                    EN
                </div>
                <div
                    onClick={() => i18n.changeLanguage('cn')}
                    style={{ fontWeight: lang === 'cn' ? 600 : 100, width: '50px' }}
                >
                    中文
                </div>
            </div>
            <div className='desc'>
                <p style={{ textAlign: 'right' }}>{t('header.description')}</p>
            </div>
        </div>
    );
}

export default Header;