import { TurnType, WaitListSlot } from '../../store/types';
import SingleSelect from './SingleSelect';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { AppTFunction } from '../../utils/i18n';
import { WaitListTimeProps } from './GenericForm';

export const waitListOptions: WaitListSlot[] = [{
    roomId: 0,
    time: undefined,
    turn: TurnType.Lunch
}, {
    roomId: 0,
    time: '13:00',
    turn: TurnType.Lunch
}, {
    roomId: 0,
    time: '15:00',
    turn: TurnType.Lunch
}, {
    roomId: 0,
    time: undefined,
    turn: TurnType.Dinner
}, {
    roomId: 0,
    time: '17:00',
    turn: TurnType.Dinner
}, {
    roomId: 0,
    time: '19:00',
    turn: TurnType.Dinner
}, {
    roomId: 0,
    time: '21:30',
    turn: TurnType.Dinner
}];

const getItem = (item: WaitListSlot, t: AppTFunction) => ({
    value: `${item.turn}${item.time}`.toLowerCase(),
    label: item.time !== undefined ? item.time : t('form.anyTimeLabel')
})

const WaitListSlotSelector = ({ turn, value, onChange }: WaitListTimeProps) => {

    const { t } = useTranslation();

    const options = useMemo(() => waitListOptions.filter(x => x.turn === turn), [turn]);

    const getLocalizedItem = useCallback((item: WaitListSlot) => {
        return getItem(item, t);
    }, [t]);

    return (
        <SingleSelect
            value={value}
            onChange={onChange}
            options={options}
            getItem={getLocalizedItem}
            placeholder={t("form.timePlaceholder")}
        />
    )
}

export default WaitListSlotSelector;