import { Provider } from 'react-redux'
import store from './store/store'
import { createRoot } from 'react-dom/client';

import './index.css'
import 'bootstrap/dist/css/bootstrap.css';

import App from './App'
import './utils/i18n';

const container = document.getElementById('root');
const root = createRoot(container!); 
root.render((
    <Provider store={store}>
        <App />
    </Provider>
));