import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { EasybookSlot, TurnType } from "../../store/types";
import SingleSelect from "./SingleSelect";
import { useTranslation } from "react-i18next";
import { useLoadQuery } from "../../store/requests";
import { SlotTimeProps } from "./GenericForm";

const formatTime = (value: string): string => {
    const date = DateTime.fromISO(value).setZone('Europe/Moscow');
    return date.toLocaleString({ hour: '2-digit', minute: '2-digit' });
}
const getItem = (item: EasybookSlot, lang: string) => {

    const description = item.table.description.length === 2
        ? item.table.description[lang === 'ru' ? 1 : 0]
        : item.table.description[lang === 'ru' ? 1 : lang === 'en' ? 0 : 2];

    const descriptionLabel = description ? ` (${description})` : '';
    return ({
        label: `${formatTime(item.startTimeUtc)} - ${formatTime(item.endTimeUtc)}${descriptionLabel}`,
        value: (item.startTimeUtc + item.table.tableId).toLowerCase()
    })
};

const areEqualSlots = (a: EasybookSlot, b: EasybookSlot, lang: string) => {
    const isSameDate = a.startTimeUtc.toLowerCase() === b.startTimeUtc.toLowerCase();
    const isSameDescription = a.table.description[lang === 'en' ? 0 : 1] === b.table.description[lang === 'en' ? 0 : 1];
    return isSameDate && isSameDescription;
}

const SlotTimeSelector = ({ value, onChange, isLoading: isSlotBeingBlocked, guests, date, turn }: SlotTimeProps) => {

    const { data, isLoading } = useLoadQuery();

    const { t, i18n } = useTranslation();

    const filter = useCallback((slot: EasybookSlot) => {
        const isEnoughForGuests = slot.numberOfGuests.includes(guests);

        const slotDate = DateTime.fromISO(slot.startTimeUtc).setZone('Europe/Moscow');
        const thisDate = DateTime.fromJSDate(date);

        const isSameTurn = turn === TurnType.Lunch ? slotDate.hour < 17 : slotDate.hour >= 17;

        const isSameDay = slotDate.year === thisDate.year
            && slotDate.month === thisDate.month
            && slotDate.day === thisDate.day;

        return isSameDay && isSameTurn && isEnoughForGuests;
    }, [guests, turn, date]);

    const preparedOptions = useMemo(() => {
        const filtered = (data?.openDates ?? []).filter(filter);

        const result: EasybookSlot[] = [];

        for (const slot of filtered) {
            if (!result.some(x => areEqualSlots(x, slot, i18n.language))) {
                result.push(slot);
            }
        }

        return result;
    }, [data, i18n.language, filter]);

    const getLocalizedItem = useCallback((slot: EasybookSlot) => {
        return getItem(slot, i18n.language);
    }, [i18n.language]);

    return (
        <SingleSelect
            value={value}
            onChange={onChange}
            options={preparedOptions}
            isLoading={isLoading || isSlotBeingBlocked}
            getItem={getLocalizedItem}
            placeholder={t("form.timePlaceholder")}
        />
    )
}

export default SlotTimeSelector;