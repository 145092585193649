import { Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Spinner } from 'reactstrap'

import Header from './components/Header/Header'
import Admin from './pages/Admin'
import { Home } from './pages/Home'

const spinnerStyle = {
    display: 'block',
    margin: '60px auto'
}

const App = () => {
    return (
        <Suspense fallback={<Spinner style={spinnerStyle} />}>
            <BrowserRouter>
                <Header />
                <div className="container">
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/admin" component={Admin} />
                    </Switch>
                </div>
            </BrowserRouter>
        </Suspense>
    )
}

export default App
