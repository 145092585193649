import { TurnType } from "../../store/types";
import SingleSelect from "./SingleSelect";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { AppTFunction } from "../../utils/i18n";
import { TurnProps } from "./GenericForm";

const getTurnOption = (value: TurnType, t: AppTFunction) => ({
    value: value.toString(),
    label: value === TurnType.Dinner ? t('types.turn.dinner') : t('types.turn.lunch')
})

const turnsOptions = [TurnType.Lunch, TurnType.Dinner];

const TurnSelector = ({ onChange, value }: TurnProps) => {

    const { t } = useTranslation();

    const getItem = useCallback((item: TurnType) => {
        return getTurnOption(item, t);
    }, [t]);

    return (
        <SingleSelect
            options={turnsOptions}
            value={value}
            getItem={getItem}
            onChange={onChange}
            placeholder={t('form.turnPlaceholder')}
        />
    )
}

export default TurnSelector;