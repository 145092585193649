import { createRef, useMemo } from "react"
import Select, { OnChangeValue } from "react-select"

export interface SingleSelectProps<T> {
    value: T | null | undefined,
    onChange: (value: T) => void,
}

type Props<T> = SingleSelectProps<T> & {
    options: T[],
    getItem: (item: T) => Option,
    placeholder?: string,
    isLoading?: boolean
}

const SingleSelect = <T extends any>({ getItem, isLoading, onChange, options, placeholder, value }: Props<T>) => {

    const ref = createRef<HTMLDivElement>();

    const handleChange = (newValue: OnChangeValue<Option, false>) => {
        const valueToSet = options.find(x => getItem(x).value === newValue!.value)!;
        onChange(valueToSet);
    }

    const preparedOptions = useMemo(() => {
        return options.map(getItem);
    }, [options, getItem]);

    const handleMenuOpen = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    
    return (
        <div ref={ref}>
            <Select
                classNamePrefix="selector"
                isMulti={false}
                isClearable={false}
                placeholder={placeholder ?? "Select..."}
                options={preparedOptions}
                value={value ? getItem(value) : null}
                onChange={handleChange}
                isLoading={isLoading}
                isSearchable={false}
                onMenuOpen={handleMenuOpen}
            />
        </div>
    )
}

export default SingleSelect;

interface Option {
    label: string,
    value: string
}