import PhoneInput from "./PhoneInput";
import { Button, FormGroup } from "reactstrap";
import { DateAvailability, EasybookSlot, TurnType, WaitListSlot } from "../../store/types"
import Checkbox from "./Checkbox";
import SlotDatePicker from "./SlotDatePicker"
import GuestSelector from "./GuestSelector"
import SlotTimeSelector from "./SlotTimeSelector";
import TextInput from "./TextInput";
import TurnSelector from "./TurnSelector";
import { useTranslation } from "react-i18next";
import WaitListSlotSelector from "./WaitListTimeSelector";
import { FormItemType } from "./form-types";
import SimpleDatePicker from "./SimpleDatePicker";
import { AppTFunction } from "../../utils/i18n";
import LoadingBox from "../LoadingBox";

interface ValueProps<T extends any> {
    value: T | undefined,
    onChange: (value: T) => void
}

export type GuestCountProps = { type: FormItemType.GuestNumber } & ValueProps<number>;
export type GuestCountForPartiesProps = { type: FormItemType.GuestNumberForParties } & ValueProps<number>;
export type TurnProps = { type: FormItemType.Turn } & ValueProps<TurnType>;
export type DateProps = { type: FormItemType.Date, turn: TurnType, guests: number, initiallyOpened: boolean } & ValueProps<[Date, DateAvailability]>;
export type DateWithoutSlotsProps = { type: FormItemType.DateWithoutSlots, turn: TurnType, guests: number } & ValueProps<[Date, DateAvailability]>;
export type SlotTimeProps = { type: FormItemType.SlotTime, isLoading: boolean, turn: TurnType, guests: number, date: Date } & ValueProps<EasybookSlot>;
export type WaitListTimeProps = { type: FormItemType.WaitListTime, turn: TurnType } & ValueProps<WaitListSlot>;
export type TextProps = { type: FormItemType.LastName | FormItemType.Email } & ValueProps<string>;
export type NameProps = { type: FormItemType.FirstName, scrollTo: boolean } & ValueProps<string>;
type PhoneProps = { type: FormItemType.Phone } & ValueProps<[string, boolean, string]>;
type AgreementProps = {
    type: FormItemType.AgreementKids
    | FormItemType.AgreementAwaiting
    | FormItemType.AgreementFullPrepayment
    | FormItemType.AgreementOnOrTwoPrepaymentOnWeekend
    | FormItemType.AgreementPartiesPrepaymentOnWeekday
    | FormItemType.AgreementPartiesPrepaymentOnWeekend
    | FormItemType.AgreementOnOrTwoPrepaymentOnWeekday
    | FormItemType.AgreementBirchGuestPrepayment
    | FormItemType.AgreementOneThousandPrepayment

} & ValueProps<boolean>;
type ButtonProps = {
    type: FormItemType.BookButton | FormItemType.WaitListButton | FormItemType.PartyButton;
    isLoading: boolean
}
type EmptyProps = { type: FormItemType.Empty, height: string };

export type FormItem = (
    GuestCountProps
    | GuestCountForPartiesProps
    | TurnProps
    | DateProps
    | DateWithoutSlotsProps
    | SlotTimeProps
    | WaitListTimeProps
    | NameProps
    | TextProps
    | PhoneProps
    | AgreementProps
    | ButtonProps
    | EmptyProps
)

interface OwnProps {
    items: FormItem[]
}

export const formItemStyle = {
    width: '310px',
    minHeight: '60px',
    height: '100%',
    margin: 'auto'
}

const renderItem = (x: FormItem, t: AppTFunction, allValid: boolean): JSX.Element => {
    switch (x.type) {
        case FormItemType.GuestNumber:
        case FormItemType.GuestNumberForParties:
            return <GuestSelector {...x} type={x.type} />;
        case FormItemType.Turn:
            return <TurnSelector {...x} />;
        case FormItemType.Date:
            return <SlotDatePicker {...x} />;
        case FormItemType.DateWithoutSlots:
            return <SimpleDatePicker {...x} />
        case FormItemType.SlotTime:
            return <SlotTimeSelector {...x} />;
        case FormItemType.WaitListTime:
            return <WaitListSlotSelector {...x} />;
        case FormItemType.FirstName:
            return <TextInput {...x} placeholder={t('form.firstNamePlaceholder')} scrollTo={x.scrollTo} />
        case FormItemType.LastName:
            return <TextInput {...x} placeholder={t('form.lastNamePlaceholder')} />
        case FormItemType.Phone:
            return <PhoneInput {...x} />
        case FormItemType.Email:
            return <TextInput {...x} placeholder={t('form.emailPlaceholder')} inputType="email" />
        case FormItemType.AgreementKids:
            return <Checkbox {...x} label={t('form.agreements.kids')} />
        case FormItemType.AgreementAwaiting:
            return <Checkbox {...x} label={t('form.agreements.awaiting')} />
        case FormItemType.AgreementFullPrepayment:
            return <Checkbox {...x} label={t('form.agreements.fullPrepayment')} />
        case FormItemType.AgreementOnOrTwoPrepaymentOnWeekend:
            return <Checkbox {...x} label={t('form.agreements.oneOrTwoPrepaymentOnWeekend')} />
        case FormItemType.AgreementOnOrTwoPrepaymentOnWeekday:
            return <Checkbox {...x} label={t('form.agreements.oneOrTwoPrepaymentOnWeekday')} />
        case FormItemType.AgreementPartiesPrepaymentOnWeekday:
            return <Checkbox {...x} label={t('form.agreements.partiesPrepaymentOnWeekday')} />
        case FormItemType.AgreementPartiesPrepaymentOnWeekend:
            return <Checkbox {...x} label={t('form.agreements.partiesPrepaymentOnWeekend')} />
        case FormItemType.AgreementOneThousandPrepayment:
            return <Checkbox {...x} label={t('form.agreements.oneThousandPrepayment')} />
        case FormItemType.AgreementBirchGuestPrepayment:
            return <Checkbox {...x} label={t('form.agreements.birchGuestPrepayment')} />
        case FormItemType.PartyButton:
        case FormItemType.BookButton:
        case FormItemType.WaitListButton:
            let label = '';
            if (x.type === FormItemType.BookButton) {
                label = t('form.button.book')
            }
            else if (x.type === FormItemType.WaitListButton) {
                label = t('form.button.waitList')
            }
            else {
                label = t('form.button.party')
            }
            return (
                <Button type="submit" className='final-button' disabled={!allValid}>
                    <LoadingBox isLoading={x.isLoading}>
                        <>{label}</>
                    </LoadingBox>
                </Button>
            );
        case FormItemType.Empty:
            return <div style={{ height: x.height }}></div>
    }
}

export const getIsItemValid = (x: FormItem): boolean => {
    switch (x.type) {
        case FormItemType.GuestNumber:
        case FormItemType.GuestNumberForParties:
        case FormItemType.Turn:
            return x.value !== undefined;
        case FormItemType.Phone:
            return x.value !== undefined && !!x.value[1];
        case FormItemType.Email:
            return /.+@.+/.test(x.value ?? '');
        case FormItemType.Date:
        case FormItemType.DateWithoutSlots:
        case FormItemType.SlotTime:
        case FormItemType.WaitListTime:
        case FormItemType.FirstName:
        case FormItemType.LastName:
        case FormItemType.AgreementKids:
        case FormItemType.AgreementAwaiting:
        case FormItemType.AgreementFullPrepayment:
        case FormItemType.AgreementOnOrTwoPrepaymentOnWeekend:
        case FormItemType.AgreementOnOrTwoPrepaymentOnWeekday:
        case FormItemType.AgreementPartiesPrepaymentOnWeekday:
        case FormItemType.AgreementPartiesPrepaymentOnWeekend:
        case FormItemType.AgreementOneThousandPrepayment:
        case FormItemType.AgreementBirchGuestPrepayment:
            return !!x.value;
        case FormItemType.PartyButton:
        case FormItemType.BookButton:
        case FormItemType.WaitListButton:
        case FormItemType.Empty:
            return true;
    }
}

const GenericForm = (props: OwnProps) => {

    const { t } = useTranslation();

    //todo
    const allValid = props.items.every(getIsItemValid);

    return (
        <>
            {props.items.map(x => (
                <FormGroup style={formItemStyle} key={x.type}>
                    {renderItem(x, t, allValid)}
                </FormGroup>
            ))}
        </>
    );
}

export default GenericForm;