import { createRef, useEffect } from "react";
import { Input } from "reactstrap";

interface OwnProps {
    value: string | undefined,
    onChange: (value: string) => void,
    placeholder?: string,
    inputType?: 'password' | 'email' | 'textarea',
    className?: string,
    scrollTo?: boolean
}

const style = {
    borderRadius: '0',
    height: '60px'
};

const TextInput = ({ onChange, value, placeholder, inputType: type, className, scrollTo }: OwnProps) => {

    const ref = createRef<HTMLInputElement>();

    useEffect(() => {
        if (scrollTo) {
            ref.current?.scrollIntoView({ behavior: 'smooth', inline: 'start' })
        }
    }, [scrollTo]);

    return (
        <Input
            innerRef={ref}
            type={type ?? "text"}
            value={value ?? ''}
            onChange={e => onChange(e.target.value)}
            placeholder={placeholder}
            style={style}
            className={className}
        />
    )
}

export default TextInput;