import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'reactstrap';
import GenericForm, { FormItem } from '../components/Form/GenericForm';
import { DateAvailability, EasybookSlot, WaitListSlot, TurnType, getTurnTypeFromString } from '../store/types'
import { useTranslation } from 'react-i18next';
import { useMessageModals } from '../components/CustomModal';
import useLoading from '../utils/useLoading';
import { DateTime } from 'luxon';
import { FormItemType } from '../components/Form/form-types';
import { useLoadQuery, releaseSlot, blockSlot, requestBooking, requestWaitList, requestParty, useLoadPopupsQuery, sendTelemetry } from '../store/requests';
import queryString from 'query-string';

const formatTime = (start: string, end: string) => {
    const startTime = DateTime.fromISO(start).setZone('UTC+3');
    const endTime = DateTime.fromISO(end).setZone('UTC+3');
    return `${startTime.toFormat('HH:mm')} - ${endTime.toFormat('HH:mm')}`;
}

const tryGetQueryString = (value: string | (string | null)[] | null) => {
    return typeof (value) === 'string' ? value : undefined;
}

export const Home = () => {

    const query = queryString.parse(location.search);
    const queryGuests = tryGetQueryString(query.guests);
    const queryTurn = tryGetQueryString(query.turn);
    const fromBirch = !!queryGuests && !!queryTurn;

    const { } = useLoadQuery();
    const { data: popupMessages } = useLoadPopupsQuery();

    const { t, i18n } = useTranslation();

    const [guestsCount, setGuestsCount] = useState<number | undefined>(queryGuests ? parseInt(queryGuests) : undefined);
    const [partyGuestsCount, setPartyGuestsCount] = useState<number>();
    const [turn, setTurn] = useState<TurnType | undefined>(queryTurn ? getTurnTypeFromString(queryTurn) : undefined);
    const [date, setDate] = useState<[Date, DateAvailability]>();
    const [time, setTime] = useState<EasybookSlot>();
    const [waitListTime, setWaitListTime] = useState<WaitListSlot>();
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [phone, setPhone] = useState<[string, boolean, string]>();
    const [email, setEmail] = useState<string>();
    const [agreementKids, setAgreementKids] = useState<boolean>();
    const [agreementAwating, setAgreementAwaiting] = useState<boolean>();
    const [agreementFullPrepayment, setAgreementFullPrepayment] = useState<boolean>();
    const [agreementOneOrTwoPrepaymentOnWeekend, setAgreementOneOrTwoPrepaymentOnWeekend] = useState<boolean>();
    const [agreementOneOrTwoPrepaymentOnWeekday, setAgreementOneOrTwoPrepaymentOnWeekday] = useState<boolean>();
    const [agreementPartiesPrepaymentOnWeekend, setAgreementPartiesPrepaymentOnWeekend] = useState<boolean>();
    const [agreementPartiesPrepaymentOnWeekday, setAgreementPartiesPrepaymentOnWeekday] = useState<boolean>();
    const [agreementBirchGuestPrepayment, setAgreementBirchGuestPrepayment] = useState<boolean>();
    const [agreementOneThousandPrepayment, setAgreementOneThousandPrepayment] = useState<boolean>();

    const [isSlotBlocking, startSlotBlocking, blockError, resetBlockError] = useLoading();
    const [isRequesting, startRequest, requestError, resetRequestError] = useLoading();

    const [messageDialog, addPopup] = useMessageModals();

    const handleDateChange = useCallback((value: [Date, DateAvailability]) => { // use effect ?
        if (value[1] === DateAvailability.FullyBooked) { // refactor: do not use t. Instead introduce type of popup: Built-In, Custom
            addPopup(t('modals.fullyBookedText'));
        }
        //todo: skip popup
        const popup = popupMessages?.find(x => x.year === DateTime.fromJSDate(value[0]).year
            && x.month === DateTime.fromJSDate(value[0]).month
            && x.day === DateTime.fromJSDate(value[0]).day);
        const popupMessage = i18n.language === 'ru' ? popup?.ruMessage : i18n.language === "en" ? popup?.enMessage : popup?.cnMessage;
        if (popupMessage) {
            addPopup(popupMessage);
        }
        setDate(value);
    }, [t, popupMessages]);

    const handleSlotChange = (slot: EasybookSlot | undefined) => {
        startSlotBlocking(async () => {
            if (time !== undefined) {
                await releaseSlot(time.id!);
            }
            if (slot) {
                const response = await blockSlot({
                    slotId: slot.id,
                    endDateTimeUtc: slot.endTimeUtc,
                    startDateTimeUtc: slot.startTimeUtc,
                    tableId: slot.table.tableId,
                    numberOfGuests: guestsCount!
                });
                setTime({ ...slot, id: response.slotId });
            }
            else {
                setTime(undefined);
            }
        })
    };

    const handleBookRequest = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        return startRequest(async () => {
            if (fromBirch) {
                sendTelemetry().then(() => console.log('sent'));
            }
            const convertedDate = DateTime.fromJSDate(date![0]);
            await requestBooking({
                day: convertedDate.day,
                email: email!,
                guestCount: guestsCount!.toString(),
                month: convertedDate.month,
                name: firstName!,
                phone: phone![2],
                roomId: 0,
                slotId: time!.id!,
                surname: lastName!,
                time: formatTime(time!.startTimeUtc, time!.endTimeUtc),
                turn: turn!,
                year: convertedDate.year
            }, fromBirch);
            addPopup(t('modals.successfullReservation'));
        });
    }

    const handleWaitListRequest = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        return startRequest(async () => {
            const convertedDate = DateTime.fromJSDate(date![0]);
            await requestWaitList({
                day: convertedDate.day,
                email: email!,
                guestCount: guestsCount!.toString(),
                month: convertedDate.month,
                name: firstName!,
                phone: phone![2],
                roomId: 0,
                surname: lastName!,
                time: waitListTime!.time ?? 'Любое',
                turn: turn!,
                year: convertedDate.year
            }, fromBirch);
            addPopup(t('modals.successfullWaitList'));
        });
    }

    const handlePartyRequest = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        return startRequest(async () => {
            const convertedDate = DateTime.fromJSDate(date![0]);
            await requestParty({
                day: convertedDate.day,
                email: email!,
                guestCount: partyGuestsCount?.toString() ?? "1",
                month: convertedDate.month,
                name: firstName!,
                phone: phone![2],
                surname: lastName!,
                turn: turn!,
                year: convertedDate.year
            });
            if (guestsCount === 1) {
                addPopup(t('modals.successfullSingleReservation'));
            }
            else {
                addPopup(t('modals.successfullReservation'));
            }
        });
    }

    const getFormItemProps = (type: FormItemType): FormItem => {
        switch (type) {
            case FormItemType.AgreementAwaiting:
                return ({
                    type,
                    value: agreementAwating,
                    onChange: setAgreementAwaiting
                });
            case FormItemType.AgreementKids:
                return ({
                    type,
                    value: agreementKids,
                    onChange: setAgreementKids
                });
            case FormItemType.AgreementOnOrTwoPrepaymentOnWeekend:
                return ({
                    type,
                    value: agreementOneOrTwoPrepaymentOnWeekend,
                    onChange: setAgreementOneOrTwoPrepaymentOnWeekend
                });
            case FormItemType.AgreementOnOrTwoPrepaymentOnWeekday:
                return ({
                    type,
                    value: agreementOneOrTwoPrepaymentOnWeekday,
                    onChange: setAgreementOneOrTwoPrepaymentOnWeekday
                });
            case FormItemType.AgreementPartiesPrepaymentOnWeekday:
                return ({
                    type,
                    value: agreementPartiesPrepaymentOnWeekday,
                    onChange: setAgreementPartiesPrepaymentOnWeekday
                });
            case FormItemType.AgreementOneThousandPrepayment:
                return ({
                    type,
                    value: agreementOneThousandPrepayment,
                    onChange: setAgreementOneThousandPrepayment
                });
            case FormItemType.AgreementBirchGuestPrepayment:
                return ({
                    type,
                    value: agreementBirchGuestPrepayment,
                    onChange: setAgreementBirchGuestPrepayment
                });
            case FormItemType.AgreementPartiesPrepaymentOnWeekend:
                return ({
                    type,
                    value: agreementPartiesPrepaymentOnWeekend,
                    onChange: setAgreementPartiesPrepaymentOnWeekend
                });
            case FormItemType.AgreementFullPrepayment:
                return ({
                    type,
                    value: agreementFullPrepayment,
                    onChange: setAgreementFullPrepayment
                });
            case FormItemType.Date:
                return ({
                    type,
                    value: date,
                    onChange: handleDateChange,
                    guests: guestsCount!,
                    turn: turn!,
                    initiallyOpened: !!queryGuests && !!queryTurn
                });
            case FormItemType.DateWithoutSlots:
                return ({
                    type,
                    value: date,
                    onChange: setDate,
                    guests: guestsCount!,
                    turn: turn!
                })
            case FormItemType.Email:
                return ({
                    type,
                    value: email,
                    onChange: setEmail
                });
            case FormItemType.FirstName:
                return ({
                    type,
                    value: firstName,
                    onChange: setFirstName,
                    scrollTo: guestsCount! < 7
                });
            case FormItemType.GuestNumber:
                return ({
                    type,
                    value: guestsCount,
                    onChange: setGuestsCount
                });
            case FormItemType.GuestNumberForParties:
                return ({
                    type,
                    value: partyGuestsCount,
                    onChange: setPartyGuestsCount
                });
            case FormItemType.LastName:
                return ({
                    type,
                    value: lastName,
                    onChange: setLastName
                });
            case FormItemType.Phone:
                return ({
                    type,
                    value: phone,
                    onChange: setPhone
                });
            case FormItemType.SlotTime:
                return ({
                    type,
                    value: time,
                    onChange: handleSlotChange,
                    date: date![0],
                    guests: guestsCount!,
                    isLoading: isSlotBlocking,
                    turn: turn!
                });
            case FormItemType.Turn:
                return ({
                    type,
                    value: turn,
                    onChange: setTurn
                });
            case FormItemType.WaitListTime:
                return ({
                    type,
                    value: waitListTime,
                    onChange: setWaitListTime,
                    turn: turn!
                });
            case FormItemType.PartyButton:
            case FormItemType.BookButton:
            case FormItemType.WaitListButton:
                return ({
                    type,
                    isLoading: isRequesting
                });
            case FormItemType.Empty:
                return ({ type, height: turn !== undefined ? '450px' : '320px' });
        }
    }

    useEffect(() => {
        if (!!blockError) {
            addPopup(t('modals.failedSlotBlocking'));
            resetBlockError();
        }
        if (!!requestError) {
            addPopup(t('modals.failedRequest'));
            resetRequestError();
        }
    }, [addPopup, t, blockError, requestError, resetBlockError, resetRequestError]);

    useEffect(() => {
        //todo do not undefine, if still available
        if (!!guestsCount && guestsCount > 1 && guestsCount < 7) {
            setDate(undefined);
        }
    }, [guestsCount, turn]);

    useEffect(() => {
        switch (guestsCount) {
            case 1:
                addPopup(t('modals.oneGuest'));
                break;
            case 6:
                addPopup(t('modals.sixGuests'));
                break;
            case 7:
                addPopup(t('modals.sevenGuests'));
                break;
        }
    }, [guestsCount, addPopup, t]);

    useEffect(() => {
        handleSlotChange(undefined)
        setWaitListTime(undefined);
    }, [date]);

    const formItemTypes = [FormItemType.GuestNumber];
    let onSubmit: ((event: React.FormEvent<HTMLFormElement>) => Promise<void>) | undefined = undefined;

    if (guestsCount !== undefined) {
        if (guestsCount > 1 && guestsCount < 7) {
            formItemTypes.push(FormItemType.Turn);

            if (turn !== undefined) {
                formItemTypes.push(FormItemType.Date);

                if (date !== undefined) {
                    if (date[1] === DateAvailability.Available) {
                        formItemTypes.push(FormItemType.SlotTime);
                    }
                    else {
                        formItemTypes.push(FormItemType.WaitListTime);
                    }
                    if (!!time || !!waitListTime) {
                        formItemTypes.push(
                            FormItemType.FirstName,
                            FormItemType.LastName,
                            FormItemType.Phone,
                            FormItemType.Email,
                            FormItemType.AgreementKids
                        );
                        if (date[1] === DateAvailability.Available) {
                            formItemTypes.push(FormItemType.AgreementAwaiting)
                        }
                        const selectedDate = DateTime.fromJSDate(date[0]);
                        if (fromBirch) {
                            formItemTypes.push(FormItemType.AgreementBirchGuestPrepayment)
                        }
                        //else if ((selectedDate.month === 2 && selectedDate.day === 14)) {
                        //    formItemTypes.push(FormItemType.AgreementFullPrepayment)
                        //}
                        else if (
                            /**(selectedDate.month === 3 && selectedDate.day === 8)
                            || (selectedDate.month === 4 && selectedDate.day >= 27)
                            || (selectedDate.month === 5 && selectedDate.day <= 12)
                            || **/(selectedDate.weekday >= 5)
                        ) {
                            formItemTypes.push(FormItemType.AgreementBirchGuestPrepayment)
                        }
                        else {
                            if (selectedDate.weekday >= 5 || guestsCount === 6) {
                                formItemTypes.push(FormItemType.AgreementPartiesPrepaymentOnWeekend)
                            }
                            else if (selectedDate.weekday <= 4
                                && (guestsCount >= 1 && guestsCount <= 5)
                                && (turn === TurnType.Dinner || guestsCount >= 4)
                            ) {
                                formItemTypes.push(FormItemType.AgreementOneThousandPrepayment)
                            }
                        }
                        if (date[1] === DateAvailability.Available) {
                            formItemTypes.push(FormItemType.BookButton);
                            onSubmit = handleBookRequest;
                        }
                        else {
                            formItemTypes.push(FormItemType.WaitListButton);
                            onSubmit = handleWaitListRequest;
                        }
                    }
                }
            }
        }
        else {
            formItemTypes.push(
                FormItemType.DateWithoutSlots,
                FormItemType.Turn,
                FormItemType.FirstName,
                FormItemType.LastName
            );
            if (guestsCount === 7) {
                formItemTypes.push(FormItemType.GuestNumberForParties);
            }
            formItemTypes.push(
                FormItemType.Phone,
                FormItemType.Email,
                FormItemType.PartyButton
            );
            onSubmit = handlePartyRequest;
        }
    }
    if (!formItemTypes.includes(FormItemType.FirstName)) {
        formItemTypes.push(FormItemType.Empty);
    }

    const formItems: FormItem[] = formItemTypes.map(getFormItemProps);

    const tips = useMemo(() => {
        return [
            t('body.bookingTip1', { defaultValue: undefined }),
            t('body.bookingTip2', { defaultValue: undefined }),
            t('body.bookingTip3', { defaultValue: undefined })
        ].filter(x => !!x);
    }, [t]);

    return (
        <>
            {tips.map((x, i) => (
                <p className='booking-tip' key={i}>{x}</p>
            ))}
            {tips.length ? <br /> : <></>}
            <Form onSubmit={onSubmit}>
                <GenericForm items={formItems} />
            </Form>
            {messageDialog}
        </>
    );
}