import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { createApi, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'
import { BookingDate, PopupMessage, ReservationModel, BookRequestBase, GuestInformationBase, EasybookSlot } from "./types";

interface ApiResponse {
    openDates: EasybookSlot[],
    now: string,
}

interface BlockSlotRequest {
    slotId: string | null,
    startDateTimeUtc: string,
    endDateTimeUtc: string,
    numberOfGuests: number,
    tableId: string
}

interface Tokens {
    accessToken: string,
    refreshToken: string
}

const customFetch = async <TBody extends any>(url: string, method: string, body?: TBody) => {
    const response = await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            ['content-type']: 'application/json'
        }
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
}

const customFetchWithResponse = async <TBody extends any>(url: string, method: string, body?: TBody) => {
    const response = await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: {
            ['content-type']: 'application/json'
        }
    });
    if (!response.ok) {
        throw new Error(response.statusText);
    }
    return Promise.resolve(await response.json());
}

export const blockSlot = (request: BlockSlotRequest): Promise<{ slotId: string }> => {
    return customFetchWithResponse('api/main/slot-blocking', 'POST', request);
}
export const releaseSlot = (slotId: string): Promise<unknown> => {
    return customFetch(`api/main/slot-blocking/${slotId}`, 'DELETE');
}
export const requestBooking = (request: ReservationModel, fromBirch: boolean): Promise<void> => {
    return customFetch(`api/main/book?fromBirch=${fromBirch}`, 'POST', request);
}
export const requestWaitList = (request: BookRequestBase, fromBirch: boolean): Promise<void> => {
    return customFetch(`api/main/wait?fromBirch=${fromBirch}`, 'POST', request);
}
export const requestParty = (request: GuestInformationBase): Promise<void> => {
    return customFetch('api/main/large-company', 'POST', request);
}
export const sendTelemetry = (): Promise<void> => {
    return customFetch('api/main/birch-booked-telemetry', 'POST');
}
export const requestAdminAuthorization = (password: string): Promise<Tokens> => {
    return customFetchWithResponse('api/main/admin/auth', 'POST', { password });
}
export const requestAdminTokenRefresh = (): Promise<Tokens> => {
    const accessToken = localStorage.getItem('access-token');
    const refreshToken = localStorage.getItem('refresh-token');
    return customFetchWithResponse('api/main/admin/refresh-token', 'POST', { accessToken, refreshToken });
}

const fetchWithTokenRefresh = async <T extends any>(getResult: () => MaybePromise<QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta>>) => {
    let result = await getResult();

    if (result.error?.status === 401) {
        const tokens = await requestAdminTokenRefresh();
        localStorage.setItem('access-token', tokens.accessToken);
        localStorage.setItem('refresh-token', tokens.refreshToken);

        result = await getResult();
    }
    
    return { ...result, data: undefined };
}

export const applicationApi = createApi({
    reducerPath: 'app',
    baseQuery: fetchBaseQuery(),
    tagTypes: ['blockedDates', 'popups'],
    endpoints: (builder) => ({
        load: builder.query<ApiResponse, void>({
            query: () => 'api/main/',
        }),
        loadBlockedDates: builder.query<BookingDate[], void>({
            query: () => 'api/main/blocked-dates',
            providesTags: ['blockedDates']
        }),
        loadPopups: builder.query<PopupMessage[], void>({
            query: () => 'api/main/popups',
            providesTags: ['popups']
        }),
        blockAdminDate: builder.mutation<void, Omit<BookingDate, 'dateAvailability'>>({
            queryFn: (arg, queryApi, extraOptions, fetchWithBQ) => {
                return fetchWithTokenRefresh(() => fetchWithBQ({
                    url: 'api/main/blocked-dates',
                    method: 'POST',
                    body: arg,
                    headers: {
                        ['Authorization']: `Bearer ${localStorage.getItem('access-token')}`
                    }
                }))
            },
            invalidatesTags: ['blockedDates']
        }),
        createOrUpdatePopup: builder.mutation<void, PopupMessage>({
            queryFn: (arg, queryApi, extraOptions, fetchWithBQ) => {
                return fetchWithTokenRefresh(() => fetchWithBQ({
                    url: 'api/main/popups',
                    method: 'POST',
                    body: arg,
                    headers: {
                        ['Authorization']: `Bearer ${localStorage.getItem('access-token')}`
                    }
                }));
            },
            invalidatesTags: ['popups']
        })
    }),
});

export const {
    useLoadQuery,
    useBlockAdminDateMutation,
    useLoadBlockedDatesQuery,
    useLoadPopupsQuery,
    useCreateOrUpdatePopupMutation
} = applicationApi;