import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./SlotDatePicker.scss";
import { useTranslation } from 'react-i18next';
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import cn from 'date-fns/locale/zh-CN';
import { createRef, forwardRef, useCallback, useEffect, useState } from "react";
import { DateAvailability } from "../../store/types";
import { DateTime } from "luxon";
import { useLoadQuery } from "../../store/requests";
import { DateWithoutSlotsProps } from "./GenericForm";
import { Input } from "reactstrap";

registerLocale('ru', ru)
registerLocale('cn', cn)

const renderDayContents = (dayOfMonth: number) => {
    return <div className="day-cell">{dayOfMonth}</div>
}

const SimpleDatePicker = ({ onChange, value, guests, turn }: DateWithoutSlotsProps) => {

    const { t, i18n } = useTranslation();
    const ref = createRef<HTMLDivElement>();

    const { data } = useLoadQuery();
    const [isOpen, setIsOpen] = useState(false);

    const now = data?.now ? DateTime.fromISO(data.now) : DateTime.now();

    const minDate = now.minus({
        hours: now.hour,
        minutes: now.minute,
        seconds: now.second,
        milliseconds: now.millisecond
    }).toJSDate();

    const getDateAvailability = useCallback((jsDate: Date) => {
        const date = DateTime.fromJSDate(jsDate);
        if (!!minDate && date < DateTime.fromJSDate(minDate)) {
            return DateAvailability.Unavailable;
        }
        return undefined;

    }, [data, turn, guests, now]);

    const handleChange = (date: Date) => {
        const availability = getDateAvailability(date);
        onChange([date, availability ?? DateAvailability.Available]);
        setIsOpen(false);
    }

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: "start" })
    }, [])

    useEffect(() => {
        if (isOpen) {
            ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [isOpen]);

    const DatePickerInput = forwardRef<HTMLInputElement>(({ ...props }, inputRef) => (
        <Input type="text" {...props} onClick={() => setIsOpen(x => !x)} innerRef={inputRef} />
    ));
    
    return (
        <div ref={ref}>
            <ReactDatePicker
                customInput={<DatePickerInput />}
                onClickOutside={() => setIsOpen(false)}
                open={isOpen}
                minDate={minDate}
                selected={value ? value[0] : undefined}
                onChange={handleChange}
                locale={i18n.language}
                dateFormat={t('datePicker.dateFormat')}
                calendarClassName="app-calendar"
                wrapperClassName="app-calendar-wrapper"
                placeholderText={t('form.datePlaceholder')}
                renderDayContents={renderDayContents}
                disabledKeyboardNavigation
            />
        </div>
    )
}

export default SimpleDatePicker;