import { Spinner } from "reactstrap";

interface OwnProps {
    children: JSX.Element,
    isLoading: boolean
}

const spinnerStyle = {
    display: 'block',
    margin: 'auto'
}

const LoadingBox = ({ children, isLoading }: OwnProps) => {

    return (
        isLoading ? <Spinner style={spinnerStyle} /> : children
    )
}

export default LoadingBox;