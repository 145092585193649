export interface BookingDate {
    year: number;
    month: number;
    day: number;
    roomId: number;
    turn: TurnType;
    dateAvailability: DateAvailability;
}

export interface PopupMessage {
    year: number;
    month: number;
    day: number;
    ruMessage: string | null;
    enMessage: string | null;
    cnMessage: string | null;
}

export enum TurnType {
    Lunch = 1,
    Dinner = 2
}

export interface GuestInformationBase {
    name: string;
    surname: string;
    email: string;
    guestCount: string;
    phone: string;
    turn: TurnType;
    year: number;
    month: number;
    day: number;
}

export interface BookRequestBase extends GuestInformationBase {
    roomId: number;
    time: string;
}

export interface ReservationModel extends BookRequestBase {
    slotId: string;
}

export interface EasybookSlot {
    id: string | null;
    numberOfGuests: number[];
    startTimeUtc: string;
    endTimeUtc: string;
    roomId: number;
    table: {
        tableId: string;
        description: string[];
    }
}

export interface WaitListSlot {
    time: string | undefined,
    roomId: number,
    turn: TurnType,
}

export enum DateAvailability {
    Unavailable = 0,
    FullyBooked = 1,
    Available = 2
}

export const getTurnTypeFromString = (value: string) => {
    switch (value.toLowerCase()) {
        case 'lunch':
            return TurnType.Lunch;
        case 'dinner':
            return TurnType.Dinner;
        default:
            return undefined;
    }
}