import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Form, FormGroup } from 'reactstrap';
import TextInput from '../components/Form/TextInput';
import useLoading from '../utils/useLoading';
import AdminCalendar from '../components/AdminCalendar/AdminCalendar';
import { formItemStyle } from '../components/Form/GenericForm';
import { requestAdminAuthorization, requestAdminTokenRefresh, useLoadBlockedDatesQuery } from '../store/requests';
import LoadingBox from '../components/LoadingBox';

const Admin = () => {
    const { t } = useTranslation();
    const { isLoading: isBlockedDatesLoading } = useLoadBlockedDatesQuery();

    const [authorized, setAuthorized] = useState(false);
    const [password, setPassword] = useState<string>('');
    const [isAuthorizing, startAuthorize] = useLoading();

    const handleAuthorize = useCallback((e?: React.FormEvent<HTMLFormElement>, usePassword: boolean = false) => {
        e?.preventDefault();
        return startAuthorize(async () => {
            let accessToken: string | null = null;
            let refreshToken: string | null = null;
            try {
                const tokens = usePassword
                    ? await requestAdminAuthorization(password)
                    : await requestAdminTokenRefresh();
                accessToken = tokens.accessToken;
                refreshToken = tokens.refreshToken;
            }
            catch { }

            if (!!accessToken && !!refreshToken) {
                localStorage.setItem('access-token', accessToken);
                localStorage.setItem('refresh-token', refreshToken);
                setAuthorized(true);
            }
            else {
                localStorage.removeItem('refresh-token');
            }
        });
    }, [startAuthorize, password]);

    useEffect(() => {
        if (!authorized) {
            const accessToken = localStorage.getItem('access-token');
            const refreshToken = localStorage.getItem('refresh-token');
            if (accessToken && refreshToken) {
                handleAuthorize(undefined, false);
            }
        }
    }, [authorized, handleAuthorize]);

    return (
        <>
            <br />
            {!authorized ? (
                <Form onSubmit={e => handleAuthorize(e, true)}>
                    <FormGroup style={formItemStyle}>{/* //todo*/}
                        <TextInput
                            value={password}
                            onChange={setPassword}
                            inputType='password'
                            placeholder={t('admin.passwordPlaceholder')}
                        />
                    </FormGroup>
                    <FormGroup style={formItemStyle}>{/* //todo*/}
                        <Button type='submit' className='final-button'> {/* //todo*/}
                            <LoadingBox isLoading={isAuthorizing}>
                                <>{t('admin.loginButtonLabel')}</>
                            </LoadingBox>
                        </Button>
                    </FormGroup>
                </Form>
            ) : (
                <LoadingBox isLoading={isBlockedDatesLoading}>
                    <AdminCalendar />
                </LoadingBox>
            )}
            <br />
            <br />
            <br />
        </>
    )
}

export default Admin;
