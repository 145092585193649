import { useCallback, useState } from "react";

type Loading = boolean;
type Starter = <T>(input: Promise<T> | (() => Promise<T>)) => Promise<T | undefined>;
type ErrorMessage = string | undefined;
type ErrorResetter = () => void

const useLoading = (): [Loading, Starter, ErrorMessage, ErrorResetter] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const startLoading = useCallback(async <T>(input: Promise<T> | (() => Promise<T>)) => {
        setIsLoading(true);
        setError(undefined);
        try {
            const result = typeof input === "function"
                ? await input()
                : await input;
            return result;
        }
        catch (e: any) {
            console.error(e);
            setError(e.toString());
            return undefined;
        }
        finally {
            setIsLoading(false);
        }
    }, []);

    const resetError = useCallback(() => setError(undefined), []);

    return [isLoading, startLoading, error, resetError];
}

export default useLoading;