import Calendar, { CalendarTileProperties } from 'react-calendar';
import { DateTime } from 'luxon';
import { DateAvailability, PopupMessage, TurnType } from '../../store/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage as faMessageRegular } from '@fortawesome/free-regular-svg-icons';
import { faMessage as faMessageSolid } from '@fortawesome/free-solid-svg-icons';
import { useDialogWithParameter } from '../CustomModal';
import AdminPopupModal from '../AdminPopupModal';
import { useTranslation } from 'react-i18next';
import './AdminCalendar.scss';
import { useLoadBlockedDatesQuery, useLoadPopupsQuery, useBlockAdminDateMutation, useLoadQuery } from '../../store/requests';
import { useCallback } from 'react';

const getButtonClassName = (availablity: DateAvailability | undefined): string => {
    switch (availablity) {
        case undefined:
        case DateAvailability.Available:
            return 'date date-available';
        case DateAvailability.FullyBooked:
            return 'date date-booked';
        case DateAvailability.Unavailable:
            return 'date date-unavailable';
    }
}

const getIsDisabled = (date: DateTime, now: DateTime) => {
    return date.startOf('day') < now.startOf('day');
}

const AdminCalendar = () => {

    const { i18n } = useTranslation();

    const { data: mainData } = useLoadQuery();
    const { data: blockedDatesData } = useLoadBlockedDatesQuery();
    const { data: popupsData } = useLoadPopupsQuery();

    const now = mainData?.now ? DateTime.fromISO(mainData.now) : DateTime.now();

    const [blockDate] = useBlockAdminDateMutation();

    const [popupDialog, openPopupDialog] = useDialogWithParameter<[PopupMessage | undefined, DateTime]>(
        ([popup, date], closeDialog) => (
            <AdminPopupModal
                closeDialog={closeDialog}
                popup={popup}
                date={date}
            />
        )
    )

    const adminDates = blockedDatesData ?? [];
    const popups = popupsData ?? [];

    const handleDateAvailabilityChange = (date: DateTime, turn: TurnType) => {
        if (getIsDisabled(date, now)) { //todo ?
            return;
        }
        blockDate({ year: date.year, month: date.month, day: date.day, roomId: 0, turn: turn });
    }

    const getIsTileDisabled = useCallback((tileProps: CalendarTileProperties) => {
        return getIsDisabled(DateTime.fromJSDate(tileProps.date), now);
    }, [now])

    const renderTile = (tileProps: CalendarTileProperties) => {
        const start = DateTime.fromJSDate(tileProps.activeStartDate);
        const date = DateTime.fromJSDate(tileProps.date);

        if (start.month !== date.month) {
            return <></>
        }

        const currentDates = adminDates.filter(x => x.year === date.year
            && x.month === date.month
            && x.day === date.day);

        const lunch = currentDates.find(x => x.turn === TurnType.Lunch)?.dateAvailability;
        const dinner = currentDates.find(x => x.turn === TurnType.Dinner)?.dateAvailability;

        const popup = popups.find(x => x.year === date.year
            && x.month === date.month
            && x.day === date.day
            && !!x.ruMessage
            && !!x.enMessage);

        return (
            <>
                <p>
                    {date.day}
                    &nbsp;
                    <FontAwesomeIcon
                        icon={!!popup ? faMessageSolid : faMessageRegular}
                        className='popup-icon'
                        onClick={() => !getIsDisabled(date, now) && openPopupDialog([popup, date])}
                    />
                </p>
                <div onClick={() => handleDateAvailabilityChange(date, TurnType.Lunch)}
                    className={getButtonClassName(lunch)}>
                    L
                </div>
                <div onClick={() => handleDateAvailabilityChange(date, TurnType.Dinner)}
                    className={getButtonClassName(dinner)}>
                    D
                </div>
            </>
        );
    }

    return (
        <>
            <Calendar
                locale={i18n.language}
                tileContent={renderTile}
                view='month'
                tileDisabled={getIsTileDisabled}
            />
            {popupDialog}
        </>
    )
}

export default AdminCalendar;