import { useMemo } from "react";
import { Input, Label } from "reactstrap";
import { v4 as guid } from 'uuid';
import './Checkbox.scss';

interface OwnProps {
    value: boolean | undefined,
    onChange: (value: boolean) => void,
    label: string
}

const Checkbox = ({ label, onChange, value }: OwnProps) => {

    const id = useMemo(() => guid(), []);

    return (
        <div className="checkbox">
            <Input
                type="checkbox"
                id={id}
                checked={value ?? false}
                onChange={e => onChange(e.target.checked)}
            />
            <Label for={id}>{label}</Label>
        </div>
    )
}

export default Checkbox;