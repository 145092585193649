import SingleSelect from "./SingleSelect";
import { useTranslation } from 'react-i18next';
import { FormItemType } from "./form-types";
import { useCallback, useMemo } from "react";
import { GuestCountForPartiesProps, GuestCountProps } from "./GenericForm";

type OwnProps = GuestCountProps | GuestCountForPartiesProps

const getGuestOption = (value: number, withPlus: boolean) => ({ //todo
    value: value.toString(),
    label: value.toString() + (value === 7 && withPlus ? '+' : '')
});

const guestsOptions = [1, 2, 3, 4, 5, 6, 7];
const guestsOptionsForParties = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

const GuestSelector = ({ onChange, value, type }: OwnProps) => {
    const { t } = useTranslation();

    const getItem = useCallback((item: number) => {
        return getGuestOption(item, type === FormItemType.GuestNumber);
    }, [type]);

    const options = useMemo(() => {
        if (type === FormItemType.GuestNumber) {
            return guestsOptions;
        }
        else {
            return guestsOptionsForParties;
        }
    }, [type]);

    return (
        <SingleSelect
            options={options}
            value={value}
            getItem={getItem}
            onChange={onChange}
            placeholder={t('form.guestCountPlaceholder')}
        />
    );
}

export default GuestSelector