import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from "react-i18next";
import { ChangeEvent } from "react";

interface OwnProps {
    value: [string, boolean, string] | undefined,
    onChange: (value: [string, boolean, string]) => void
}

const style = {
    width: '310px',
    height: '60px',
    borderRadius: '0'
}

const PhoneInput = ({ onChange, value }: OwnProps) => {

    const { t } = useTranslation();

    const handleChange = (
        newValue: string,
        data: CountryData | {},
        event: ChangeEvent<HTMLInputElement>,
        formattedValue: string
    ) => {
        event.preventDefault();
        const format = (data as CountryData)?.format;
        const isValid = !format || formattedValue.replaceAll(/\d/g, '.') === format;
        onChange([newValue, isValid, formattedValue]);
    }

    return (
        <ReactPhoneInput
            value={value ? value[0] : undefined}
            onChange={handleChange}
            country={'ru'}
            inputStyle={style}
            placeholder={t('form.phonePlaceholder')}
        />
    );
}

export default PhoneInput;