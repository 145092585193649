import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupMessage } from '../store/types';
import CustomModal from './CustomModal';
import TextInput from './Form/TextInput';
import './AdminPopupModal.scss';
import { useCreateOrUpdatePopupMutation } from '../store/requests';

interface OwnProps {
    popup: PopupMessage | undefined,
    date: DateTime,
    closeDialog: CallableFunction
}

const AdminPopupModal = ({ popup, closeDialog, date }: OwnProps) => {

    const { t } = useTranslation();
    const [createOrUpdatePopup] = useCreateOrUpdatePopupMutation();
    const [ruMessage, setRuMessage] = useState(popup?.ruMessage ?? '');
    const [enMessage, setEnMessage] = useState(popup?.enMessage ?? '');
    const [cnMessage, setCnMessage] = useState(popup?.cnMessage ?? '');

    const handleSubmit = async () => {
        await createOrUpdatePopup({
            day: date.day,
            month: date.month,
            year: date.year,
            enMessage,
            ruMessage,
            cnMessage
        }).unwrap();
        return true;
    }

    return (
        <CustomModal closeDialog={closeDialog} title='todo' onSubmit={handleSubmit}>
            <>
                <TextInput
                    className='popup-text'
                    inputType='textarea'
                    value={ruMessage}
                    onChange={setRuMessage}
                    placeholder={t('admin.ruMessageLabel')}
                />
                <br />
                <TextInput
                    className='popup-text'
                    inputType='textarea'
                    value={enMessage}
                    onChange={setEnMessage}
                    placeholder={t('admin.enMessageLabel')}
                />
                <br />
                <TextInput
                    className='popup-text'
                    inputType='textarea'
                    value={cnMessage}
                    onChange={setCnMessage}
                    placeholder={t('admin.cnMessageLabel')}
                />
            </>
        </CustomModal>
    )
}

export default AdminPopupModal;