import { useCallback, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

interface OwnProps {
    children: JSX.Element | string,
    closeDialog: CallableFunction,
    title: string,
    onSubmit?: () => Promise<boolean>,
    onToggle?: () => Promise<boolean>
}

const buttonStyle = ({
    backgroundColor: 'transparent',
    color: 'black',
    fontSize: 'large',
    border: 0,
    borderRadius: 0,
    width: '100%'
});

const CustomModal = ({ children, closeDialog, onSubmit, onToggle }: OwnProps) => {

    const [isOpen, setIsOpen] = useState(true);
    const [isOperationInProgress, setOperationInProgress] = useState(false);

    const closeDialogWithTimeout = async () => {
        if (!isOperationInProgress) {
            setIsOpen(false);
            if (onToggle) {
                await onToggle();
            }
            setTimeout(() => closeDialog(), 500);
        }
    };

    const executeOperation = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setOperationInProgress(true);
        try {
            const result = onSubmit ? await onSubmit() : true;
            setOperationInProgress(false);
            if (result) {
                closeDialogWithTimeout();
            }
        } catch {
            setOperationInProgress(false);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={closeDialogWithTimeout} >
            {/* <ModalHeader className="long-text-modal-header">
                {title}
            </ModalHeader> */}
            <ModalBody>
                {/* //todo: loading box */}
                <div style={{ textAlign: 'center' }}>
                    {children}
                </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent: 'center' }}>
                <Button
                    style={buttonStyle}
                    disabled={isOperationInProgress}
                    onClick={executeOperation}
                >
                    OK
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default CustomModal;

export function useDialog(dialogCreator: (closeDialog: CallableFunction) => JSX.Element | undefined): [JSX.Element | undefined, () => void] {

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const closeDialog = useCallback(() => {
        setIsDialogOpen(false);
    }, []);

    const opendDialog = useCallback(() => {
        setIsDialogOpen(true)
    }, []);

    let dialog = isDialogOpen ? dialogCreator(closeDialog) : undefined;

    return [dialog, opendDialog];
}

export function useDialogWithParameter<T>(dialogCreator: (p: T, closeDialog: CallableFunction) => JSX.Element | undefined): [JSX.Element | undefined, (parameter: T) => void] {

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [parameter, setParameter] = useState<T>();

    const closeDialog = useCallback(() => {
        setIsDialogOpen(false);
    }, []);

    const opendDialog = useCallback((value: T) => {
        setParameter(value);
        setIsDialogOpen(true);
    }, []);

    let dialog = isDialogOpen && parameter !== undefined ? dialogCreator(parameter, closeDialog) : undefined;

    return [dialog, opendDialog];
}

export const useMessageModals = (): [JSX.Element | undefined, (value: string) => void] => {
    const [popups, setPopups] = useState<string[]>([]);

    const [messageDialog, openMessageDialog] = useDialogWithParameter<string>((message, close) => {
        const onSubmit = () => {
            setPopups(x => x.slice(1));
            return Promise.resolve(true);
        }

        return (
            <CustomModal closeDialog={close} title='TODO' onToggle={onSubmit} >
                {message}
            </CustomModal>
        );
    });

    useEffect(() => {
        if (popups[0]) {
            setTimeout(() => openMessageDialog(popups[0]), 600);
        }
    }, [popups, openMessageDialog]);

    const addPopup = useCallback((message: string) => {
        setPopups(x => x.includes(message) ? x : [...x, message]);
    }, []);

    return [messageDialog, addPopup];
}