import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./SlotDatePicker.scss";
import { useTranslation } from 'react-i18next';
import { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { createRef, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { DateAvailability, TurnType } from "../../store/types";
import { DateTime } from "luxon";
import { Col, Input, Row } from "reactstrap";
import { useLoadBlockedDatesQuery, useLoadQuery } from "../../store/requests";
import LoadingBox from "../LoadingBox";
import { DateProps } from "./GenericForm";
registerLocale('ru', ru)

const renderDayContents = (dayOfMonth: number) => {
    return <div className="day-cell">{dayOfMonth}</div>
}

const LegendItem = ({ className, translationKey, marginTop }: {
    className: string,
    translationKey: string,
    marginTop?: string
}) => {

    const { t } = useTranslation();

    return (
        <Row style={{ marginBottom: '3px' }}>
            <Col xs={3}>
                <div className={`react-datepicker__day ${className}`} style={{ display: 'block' }}>
                    {renderDayContents(2)}
                </div>
            </Col>
            <Col xs={9}>
                <small style={{ margin: 'auto', marginTop: marginTop, display: 'block' }}>
                    {t(translationKey)}
                </small>
            </Col>
        </Row>
    );
}

const SlotDatePicker = ({ onChange, value, guests, turn, initiallyOpened }: DateProps) => {

    const { t, i18n } = useTranslation();
    const { data, isLoading: isDataLoading } = useLoadQuery();
    const { data: blockedDates, isLoading: isBlockedDatesLoading } = useLoadBlockedDatesQuery();
    const [isOpen, setIsOpen] = useState(false);
    const ref = createRef<HTMLDivElement>();

    const now = data?.now ? DateTime.fromISO(data.now) : DateTime.now();

    const excludedDates = useMemo(() => {
        return (blockedDates ?? [])
            .filter(x => x.dateAvailability === DateAvailability.Unavailable && x.turn === turn)
            .map(x => new Date(x.year, x.month - 1, x.day)); //todo
    }, [blockedDates]);

    const minDate = now.minus({
        hours: now.hour,
        minutes: now.minute,
        seconds: now.second,
        milliseconds: now.millisecond
    }).toJSDate();

    const maxDate = now.plus({
        days: (now.hour >= 11 ? 7 : 6) + 23 // (guests > 2 ? 23 : 0)
    }).toJSDate();

    const getDateAvailability = useCallback((jsDate: Date) => {
        const date = DateTime.fromJSDate(jsDate);

        if (!!minDate && date < DateTime.fromJSDate(minDate)) {
            return DateAvailability.Unavailable;
        }
        if (!!maxDate && date > DateTime.fromJSDate(maxDate)) {
            return DateAvailability.Unavailable;
        }

        const getAdminAvailability = (): DateAvailability => {
            const adminBlocked = blockedDates?.find(x => x.year === date.year
                && x.month === date.month
                && x.day === date.day
                && x.turn == turn);

            return adminBlocked?.dateAvailability ?? DateAvailability.Available;
        }

        const adminAvailability = getAdminAvailability();

        if (adminAvailability !== DateAvailability.Available) {
            return adminAvailability;
        }

        const canBeBooked = data?.openDates?.some(x => {
            const xDate = DateTime.fromISO(x.startTimeUtc);
            return xDate.year === date.year
                && xDate.month === date.month
                && xDate.day === date.day
                && x.numberOfGuests.includes(guests)
                && (turn === TurnType.Lunch ? (xDate.hour < 17) : (xDate.hour >= 17));
        });

        return canBeBooked ? DateAvailability.Available : DateAvailability.FullyBooked;
    }, [data, blockedDates, turn, guests, excludedDates, now, maxDate]);

    const getDayClassName = useCallback((jsDate: Date) => {
        const availability = getDateAvailability(jsDate);

        switch (availability) {
            case DateAvailability.Available:
                return 'day--available';
            case DateAvailability.FullyBooked:
                return 'day--booked';
            default:
                return '';
        }
    }, [getDateAvailability]);

    const handleChange = (date: Date) => {
        const availability = getDateAvailability(date);
        onChange([date, availability ?? DateAvailability.Available]);
        setIsOpen(false);
    }

    useEffect(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: "start" })
    }, [])
    
    useEffect(() => {
        if (isOpen) {
            ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [isOpen]);

    const DatePickerInput = forwardRef<HTMLInputElement>(({ ...props }, inputRef) => (
        <Input type="text" {...props} onClick={() => setIsOpen(x => !x)} innerRef={inputRef} />
    ));
    
    return (
        <LoadingBox isLoading={isDataLoading || isBlockedDatesLoading}>
            <div ref={ref}>
                <ReactDatePicker
                    customInput={<DatePickerInput />}
                    onClickOutside={() => setIsOpen(false)}
                    open={isOpen}
                    minDate={minDate}
                    maxDate={maxDate}
                    excludeDates={excludedDates}
                    selected={value ? value[0] : undefined}
                    onChange={handleChange}
                    locale={i18n.language}
                    dateFormat={t('datePicker.dateFormat')}
                    calendarClassName="app-calendar"
                    dayClassName={getDayClassName}
                    wrapperClassName="app-calendar-wrapper"
                    placeholderText={t('form.datePlaceholder')}
                    renderDayContents={renderDayContents}
                    disabledKeyboardNavigation                    
                    startOpen={initiallyOpened}
                >
                    <LegendItem
                        className="day--booked"
                        translationKey="datePicker.legend.booked"
                    />
                    <LegendItem
                        className="react-datepicker__day--disabled"
                        translationKey="datePicker.legend.unavailable"
                        marginTop="11px"
                    />
                    <LegendItem
                        className="day--available"
                        translationKey="datePicker.legend.available"
                        marginTop="11px"
                    />
                </ReactDatePicker>
            </div>
        </LoadingBox>
    )
}

export default SlotDatePicker;